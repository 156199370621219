import service from "../service.js";

//查询变电站安全工器具信息
export const searchStationInfo = params => {
    return service({
        method: 'POST',
        url: '/stationInfo/searchStationInfo',
        params
    })
}

export const addStationInfo = (data) => {
    return service({
        method: 'POST',
        url: '/stationInfo/addStationInfo',
        data
    })
}

export const updateStationInfo = (data) => {
    return service({
        method: 'POST',
        url: '/stationInfo/updateStationInfo',
        data
    })
}

export const scrapStationInfo = (id) => {
    return service({
        method: 'GET',
        url: '/stationInfo/scrapStationInfo',
        params: {
            id
        }
    })
}

export const queryStationInfo = (id) => {
    return service({
        method: 'GET',
        url: '/stationInfo/queryStationInfo',
        params: {
            id
        }
    })
}

export const getToolStatus = () => {
    return service({
      method: 'GET',
      url: '/param/getToolStatus',
    })
}




